import React from "react"
import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import {
  isHeading,
  isParagraph,
  renderRule,
} from "datocms-structured-text-utils"
import { Box, Text, Container, Grid, Flex, Heading } from "@theme-ui/components"
import Layout from "../components/layout"
import { getArticlePath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import ImageGallery from "../components/blocks/imageGallery"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { useFavicon } from "../hooks/useFavicon"
import PageHero from "./pageHero"
import ContactForm from "../components/blocks/contactForm"
import FilterMetaTagDescription from "../utils/filterMetaTagDescription"

const Article = ({ data: { page, articles } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  // console.log(page)
  const i18nPaths = page._allSlugLocales.map(path => {
    return {
      locale: path.locale,
      value: getArticlePath(page, path.locale),
    }
  })

  // console.log(page)

  const ldJson = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    headline: page.seo.title,
    author: {
      "@type": "Organization",
      name: "Socaf S.p.a.",
      logo: {
        "@type": "ImageObject",
        url: "https://www.datocms-assets.com/60899/1644399196-logo.svg",
      },
    },
    image: {
      "@type": "ImageObject",
      url: page.heroImage.gatsbyImageData.images.fallback.src,
    },
    datePublished: page.meta.firstPublishedAtIso,
    publisher: {
      "@type": "Organization",
      name: "Socaf S.p.a.",
      logo: {
        "@type": "ImageObject",
        url: "https://www.datocms-assets.com/60899/1644399196-logo.svg",
      },
    },
    dateModified: page.meta.updatedAt,
    description: page.seo.description,
    url: `https://socaf.it${getArticlePath(page, page.locale)}`,
    identifier: `https://socaf.it${getArticlePath(page, page.locale)}`,
  }

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths} color="dark">
      <HelmetDatoCms
        seo={FilterMetaTagDescription(page.seoMetaTags)}
        favicon={favicon}
      >
        <html lang={page.locale} />
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </HelmetDatoCms>
      <PageHero page={page} color="dark" />
      <Box>
        <Container>
          <Grid columns={["1fr", ".3fr .7fr"]}>
            <Box
              sx={{
                borderTop: "1px solid",
                borderColor: "dark",
                py: [5],
                strong: {
                  color: "primary",
                  fontWeight: "500",
                },
              }}
            >
              <Flex
                sx={{
                  textTransform: "capitalize",
                  textAlign: "left",
                  color: "dark",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Heading
                    as="p"
                    sx={{
                      lineHeight: ".8",
                      fontSize: [9],
                      color: "dark",
                      fontWeight: "500",
                      textAlign: "left",
                      mr: [4],
                    }}
                  >
                    {page.meta.firstPublishedAt.split(/(\s+)/)[0]}
                  </Heading>
                </Box>
                <Box
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "left",
                    color: "dark",
                  }}
                >
                  <Box>
                    {
                      page.meta.firstPublishedAt
                        .split(/(\s+)/)
                        .filter(e => e.trim().length > 0)[1]
                    }
                  </Box>
                  <Box>
                    {
                      page.meta.firstPublishedAt
                        .split(/(\s+)/)
                        .filter(e => e.trim().length > 0)[2]
                    }
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Box
                sx={{
                  mb: 5,
                  a: {
                    color: "inherit",
                    textDecoration: "underline",
                  },
                  ".gatsby-image-wrapper": { width: "100%", mb: [2, 5] },
                }}
              >
                <GatsbyImage image={page.heroImage.gatsbyImageData} />
                <StructuredText
                  data={page.body}
                  customRules={[
                    renderRule(
                      isHeading,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text
                                as={`h${node.level}`}
                                variant={`h${node.level}`}
                                sx={{ mb: 3 }}
                              >
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                    renderRule(
                      isParagraph,
                      ({ adapter: { renderNode }, node, children, key }) => {
                        return renderNode(
                          () => {
                            return (
                              <Text as="p" mb={3} variant="article">
                                {children}
                              </Text>
                            )
                          },
                          { key },
                          children
                        )
                      }
                    ),
                  ]}
                  renderBlock={({ record }) => {
                    // console.log(record)
                    switch (record.__typename) {
                      case "DatoCmsImageGallery":
                        return (
                          <Box mt={5} mb={5}>
                            <ImageGallery
                              images={record.images}
                              key={record.id}
                            />
                          </Box>
                        )
                      default:
                        return null
                    }
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <ContactForm
            title="Richiedi maggiori informazioni"
            subtitle="Compila il form"
            description="Verrai contattato da un nostro consulente"
          />
        </Container>
      </Box>
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ArticleQuery($id: String!, $locale: String!) {
    page: datoCmsArticle(id: { eq: $id }) {
      ...ArticleDetails
      ...ArticleAllSlugLocales
      ...ArticleMeta
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    articles: allDatoCmsArticle {
      nodes {
        ...ArticleDetails
        ...ArticleAllSlugLocales
        ...ArticleMeta
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  }

  fragment ArticleMeta on DatoCmsArticle {
    meta {
      firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
      firstPublishedAtIso: firstPublishedAt
      updatedAt
    }
  }

  fragment ArticleAllSlugLocales on DatoCmsArticle {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment ArticleDetails on DatoCmsArticle {
    id
    locale
    title
    slug
    model {
      apiKey
    }
    heroImage {
      blend: gatsbyImageData(
        width: 600
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    seo {
      description
      title
    }
    ...ArticleBody
  }

  fragment ArticleBody on DatoCmsArticle {
    body {
      value
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
      }
    }
  }

  fragment ImageGallery on DatoCmsImageGallery {
    images {
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
      alt
      title
    }
    model {
      apiKey
    }
  }
`
